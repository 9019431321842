import React, { Component } from "react";

import { Tabs } from "antd";

import Template from "./templates/Templates";
import LookUps from "./lookups/TemplateLookup";

import Transform from "./transformers/Transform";

const { TabPane } = Tabs;

class TemplateManager extends Component {
  state = {};
  render() {
    return (
      <div>
        <h1>Templates</h1>
        <Tabs defaultActiveKey="1" type="card" style={{ marginBottom: 32 }}>
          <TabPane tab="Templates" key="1">
            <Template />
          </TabPane>
          <TabPane tab="Template Lookup" key="2">
            <LookUps />
          </TabPane>
          <TabPane tab="Transformers" key="3">
            <Transform />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default TemplateManager;
