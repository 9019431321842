import React, { Component } from "react";

import { Form, Input, Button, Drawer, Col, Row, Popconfirm } from "antd";

class TermFrom extends Component {
  state = {};

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.form.resetFields();
        this.props.onAddTerm({ ...values });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedTerm } = this.props;
    // this.props.form.setFieldsValue({
    //   ...selectedTerm
    // });
    return (
      <Drawer
        title="Create a new Term"
        width={720}
        onClose={this.onClose}
        visible={this.props.onVisibleCreateView}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Year">
                {getFieldDecorator("year", {
                  initialValue: selectedTerm ? selectedTerm.year : "",
                  rules: [{ required: true, message: "Please enter year" }]
                })(<Input placeholder="Please enter zid" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Term">
                {getFieldDecorator("term", {
                  initialValue: selectedTerm ? selectedTerm.term : "",
                  rules: [{ required: true, message: "Please enter term" }]
                })(<Input placeholder="Please enter  term" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Label">
                {getFieldDecorator("label", {
                  initialValue: selectedTerm ? selectedTerm.label : "",
                  rules: [{ required: true, message: "Please enter label" }]
                })(<Input placeholder="Please enter label" />)}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 16 }}>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => this.props.onCancel()}
            >
              <Button style={{ marginRight: "10px" }} type="danger">
                Cancel
              </Button>
            </Popconfirm>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    );
  }
}

export default Form.create({ name: "register" })(TermFrom);
