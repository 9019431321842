import React, { Component } from "react";
import Highlighter from "react-highlight-words";

import {
  Table,
  Button,
  Divider,
  Popconfirm,
  Input,
  Icon,
  notification,
  Tag,
} from "antd";

import axios from "axios";

import TermForm from "./TermForm";

class Term extends Component {
  state = {
    dataSource: [],
    onVisibleCreateView: false,
    fetching: true,
    searchText: "",
  };

  componentDidMount = async () => {
    const response = await axios.get("admin/term/");
    const dataSource = response.data;
    this.setState({ dataSource, fetching: false });
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

  setCurrent = async (id) => {
    const response = await axios.put("admin/term/current/", { id });
    this.setState({ dataSource: response.data });
  };

  handleOnAddTerm = async (course) => {
    const { selectedRow } = this.state;
    const newCourse = { ...selectedRow, ...course };
    this.setState({ fetching: true });

    try {
      const response = selectedRow
        ? await axios.put("admin/term/", newCourse)
        : await axios.post("admin/term/", newCourse);
      const index = this.state.dataSource.findIndex(
        (record) => record.id === response.data.id
      );

      if (selectedRow) {
        this.openNotificationWithIcon("success", "Record updated successfully");
      } else {
        this.openNotificationWithIcon("success", "Record created successfully");
      }

      const dataSource =
        index < 0
          ? [...this.state.dataSource, response.data]
          : [
              ...this.state.dataSource.slice(0, index),
              response.data,
              ...this.state.dataSource.slice(index + 1),
            ];
      this.setState({
        dataSource,
        onVisibleCreateView: false,
        fetching: false,
        selectedRow: undefined,
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false, selectedRow: undefined });
    }
  };

  handleOnDeleteStudent = async (id) => {
    this.setState({ fetching: true });
    try {
      await axios.delete("admin/term/", { data: { id } });
      const dataSource = this.state.dataSource.filter(
        (record) => record.id !== id
      );

      this.setState({ dataSource, fetching: false });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  searchEntry(dataSource, searchText) {
    const text = searchText.toLowerCase();
    return dataSource.filter(
      (it) =>
        (!it.label && !it.description) ||
        (it.label && it.label.toLowerCase().includes(text)) ||
        (it.description && it.description.toLowerCase().includes(text))
    );
  }

  TableView = () => {
    const { dataSource, fetching, searchText } = this.state;

    const columns = [
      { title: "Year", key: "year", dataIndex: "academic_year" },
      { title: "Term", key: "term", dataIndex: "term" },
      {
        title: "Label",
        key: "label",
        dataIndex: "label",
        render: (text) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        },
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: (text) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        },
      },
      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <span>
            <Button
              type="link"
              //disabled={record.saved}
              disabled={true}
              onClick={() =>
                this.setState({
                  selectedRow: record,
                  onVisibleCreateView: true,
                })
              }
            >
              Edit
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleOnDeleteStudent(record.id)}
            >
              <Button disabled={true} type="link">
                Delete
              </Button>
            </Popconfirm>
            <Divider type="vertical" />
            {record.is_current ? (
              <Tag color="red">current</Tag>
            ) : (
              <Button type="link" onClick={() => this.setCurrent(record.id)}>
                Set Current
              </Button>
            )}
          </span>
        ),
      },
    ];

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px" }}>
            Terms
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%",
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title, Code"
              onChange={(e) => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />

            <>
              <Button
                style={{ marginLeft: "10px", float: "right" }}
                type="primary"
                disabled={!this.state.selectedRow}
                onClick={this.onCreateDuplicate}
              >
                Create Copy
              </Button>
              <Button
                style={{ marginLeft: "10px", float: "right" }}
                type="primary"
                disabled={true}
                onClick={() => {
                  this.setState({
                    onVisibleCreateView: true,
                    selectedRow: undefined,
                  });
                }}
              >
                Create New
              </Button>
            </>
          </div>
          <Table
            rowKey={(record) => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
            pagination={{
              position: "both",
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
            }}
          />
          <TermForm
            onAddTerm={this.handleOnAddTerm}
            onVisibleCreateView={this.state.onVisibleCreateView}
            selectedTerm={this.state.selectedRow}
            onCancel={() =>
              this.setState({
                onVisibleCreateView: false,
                selectedRow: undefined,
              })
            }
          />
        </div>
      </>
    );
  };
  render() {
    return this.TableView();
  }
}

export default Term;
