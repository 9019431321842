import React, { Component } from "react";
import { Spin, Table, Divider, Row, Col, Icon, Button, Input } from "antd";
import axios from "axios";

class UserResponse extends Component {
  state = { fetching: true, searchText: "", dataSource: []};

  componentDidMount = async () => {
    console.log(this.props.selectedUser)
    const { data : dataSource } = await axios.get(`survey/get_user_responses?zid=${this.props.selectedUser.zid}`)
    // const { data : dataSource } = await axios.get(`survey/get_user_responses?zid=z5019338`)
    console.log(dataSource)
    this.setState({ dataSource })
  };

  searchEntry(dataSource, searchText) {
    const text = searchText.toLowerCase();
    return dataSource.filter(
      (it) =>
        (!it.zid && !it.first_name && !it.last_name) ||
        (it.zid && it.zid.toLowerCase().includes(text)) ||
        (it.full_name && it.full_name.toLowerCase().includes(text)) ||
        (it.email && it.email.toLowerCase().includes(text))
    );
  }

  responseTable = () => {
    const { dataSource, searchText } = this.state
    const columns = [
      {
        title: "Response ID",
        key: "id",
        dataIndex: "id",
        render: (text, record) => {
          return (
            <p>
              {text}
            </p>
          )
        }
      },
      {
        title: "Course",
        key: "course",
        dataIndex: "course",
        render: (text, record) => {
          return (
            <p>
              {text}
            </p>
          )
        }
      },
      {
        title: "Survey Title",
        key: "survey_title",
        dataIndex: "survey_title",
        render: (text, record) => {
          return (
            <p>
              {text}
            </p>
          )
        }
      }
    ]
    return (
      <div style={{ width: "100%" }}>
        <span style={{ marginLeft: "10px", marginBottom: "20px" }}>
          User: {this.props.selectedUser.zid}
        </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%",
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title, Code"
              onChange={(e) => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />
          </div>
          <Table
            rowKey={(record) => record.id}
            bordered
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
            pagination={{
              position: "both",
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
            }}
          />
        </div>
    )
  }

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Button
          onClick={() => {this.props.goBack()}}
        >
          Back
        </Button>
        {this.responseTable()}
      </div>
    );
  }
}
export default UserResponse;
