import React, { Component } from "react";
import Highlighter from "react-highlight-words";

import {
  Table,
  Button,
  Divider,
  Popconfirm,
  Input,
  Icon,
  notification,
  Tooltip,
} from "antd";

import axios from "axios";

import GroupForm from "./GroupForm";

class Group extends Component {
  state = {
    dataSource: [],
    surveys: [],
    onVisibleCreateView: false,
    fetching: true,
    searchText: "",
  };

  componentDidMount = async () => {
    const { data: dataSource } = await axios.get("course/group/");
    const { data: surveys } = await axios.get("survey/");

    this.setState({ dataSource, surveys, fetching: false });
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };
  handleOnAddGroup = async (course) => {
    const { selectedRow } = this.state;
    this.setState({ fetching: true });
    const newGroup = { ...selectedRow, ...course };

    try {
      const response = selectedRow
        ? await axios.put("course/group/", newGroup)
        : await axios.post("course/group/", newGroup);
      const index = this.state.dataSource.findIndex(
        (record) => record.id === response.data.id
      );

      if (selectedRow) {
        this.openNotificationWithIcon("success", "Record updated successfully");
      } else {
        this.openNotificationWithIcon("success", "Record created successfully");
      }

      const dataSource =
        index < 0
          ? [...this.state.dataSource, response.data]
          : [
              ...this.state.dataSource.slice(0, index),
              response.data,
              ...this.state.dataSource.slice(index + 1),
            ];
      this.setState({
        dataSource,
        onVisibleCreateView: false,
        fetching: false,
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      this.setState({ fetching: false });
      console.log(error);
    }
  };

  handleOnDeleteGroup = async (id) => {
    try {
      await axios.delete("course/group/", { data: { id } });
      const dataSource = this.state.dataSource.filter(
        (record) => record.id !== id
      );

      this.setState({ dataSource });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
    }
  };

  searchEntry(dataSource, searchText) {
    const text = searchText.toLowerCase();
    
    return dataSource.filter(
      (it) => {
        // combine all courses.
        let courses = ""
        it.courses.forEach(course => {
          courses += `${course.code.toLowerCase()} - ${course.title.toLowerCase()}, `
        })

        return (
          (!it.title && !it.description) ||
          (it.title && it.title.toLowerCase().includes(text)) ||
          (it.description && it.description.toLowerCase().includes(text)) ||
          (it.id && it.id.toString().includes(text)) ||
          (courses && courses.includes(text))
        )
      }
        
    );
  }

  TableView = () => {
    const { dataSource, fetching, searchText, surveys } = this.state;

    const columns = [
      {
        title: "ID",
        key: "id",
        dataIndex: "id",
        sorter: (a, b) => {
          if (a.id < b.id) return 1
          else if (a.id > b.id) return -1
          return 0
        },
        render: (text) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        }
      },
      {
        title: "Title",
        key: "title",
        dataIndex: "title",
        sorter: (a, b) => {
          if (a.title < b.title) return 1
          else if (a.title > b.title) return -1
          return 0
        },
        render: (text) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        },
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        sorter: (a, b) => {
          if (a.description < b.description) return 1
          else if (a.description > b.description) return -1
          return 0
        },
        render: (text, record) => {
          let courses = ""
          for (let i = 0; i < record.courses.length; i++) {
            if (i < record.courses.length-1) courses += `${record.courses[i].code}, `
            else courses += `${record.courses[i].code}`
          }
          let superString = text + " - " + "[" + courses + "]"
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={superString.toString()}
            />
          ) : (
            {}
          );
        },
      },
      {
        title: "No Of Courses",
        key: "courses",
        dataIndex: "courses",
        render: (text, record) => {
          return (
            <span>
              {text.length ? (
                <Tooltip
                  placement="topLeft"
                  title={text.map((course, i) => (
                    <div key={course.id}>
                      <span>{`(${course.code}) ${course.title}${
                        i == !(text.length - 1) ? "," : ""
                      } `}</span>
                    </div>
                  ))}
                >
                  <Button type="link">{text.length}</Button>
                </Tooltip>
              ) : (
                <Button type="link">{text.length}</Button>
              )}
            </span>
          );
        },
      },
      {
        title: "No Of Surveys",
        key: "surveys",
        dataIndex: "surveys",
        render: (text, record) => {
          const currentGroupSurveys = text.map((survey) => ({
            ...survey,
            title:
              survey.preferred_title !== ""
                ? survey.preferred_title
                : surveys.find((s) => s.id === survey.survey).title,
          }));

          return (
            <span>
              {text.length ? (
                <Tooltip
                  placement="topLeft"
                  title={currentGroupSurveys.map((survey, i) => (
                    <div key={survey.id}>
                      {`${survey.title}${i == !(text.length - 1) ? "," : ""}`}{" "}
                    </div>
                  ))}
                >
                  <Button type="link">{text.length}</Button>
                </Tooltip>
              ) : (
                <Button type="link">{text.length}</Button>
              )}
            </span>
          );
        },
      },
      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <span>
            <Button
              type="link"
              disabled={record.saved}
              onClick={() =>
                this.setState({
                  selectedRow: record,
                  onVisibleCreateView: true,
                })
              }
            >
              Edit
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleOnDeleteGroup(record.id)}
            >
              <Button disabled={!record.is_deletable} type="link">
                Delete
              </Button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px" }}>
            Groups
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%",
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title, Description"
              onChange={(e) => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />

            <>
              <Button
                style={{ marginLeft: "10px", float: "right" }}
                type="primary"
                onClick={() => {
                  this.setState({
                    onVisibleCreateView: true,
                    selectedRow: undefined,
                  });
                }}
              >
                Create New
              </Button>
            </>
          </div>
          <Table
            rowKey={(record) => record.id}
            bordered
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
            loading={fetching}
            pagination={{
              position: "both",
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
              defaultPageSize: this.getPageSize()
            }}
            onChange={(e) => this.setPageSize(e.pageSize)}
          />
        </div>
      </>
    );
  };

  getPageSize = () => {
    const size = localStorage.getItem("tm-group-pageSize")
    if (size) return parseInt(size)
    return 10
  }

  setPageSize = (pageSize) => {
    localStorage.setItem("tm-group-pageSize", pageSize)
  }

  render() {
    const { onVisibleCreateView, surveys } = this.state;
    return onVisibleCreateView ? (
      <GroupForm
        surveys={surveys}
        onAddcourse={this.handleOnAddGroup}
        onVisibleCreateView={this.state.onVisibleCreateView}
        selectedGroup={this.state.selectedRow}
        onCancel={() => this.setState({ onVisibleCreateView: false })}
      />
    ) : (
      this.TableView()
    );
  }
}

export default Group;
