import React, { Component } from "react";
// import img1 from "./img1.png";
import img2 from "./resources/bg-pattern.png";
import { Col, Row, Result } from "antd";

import TextView from "./TextView";
import ChartView from "./ChartView";

import ItemListView from "./ItemListView";

import axios from "axios";

import "./resources/style.css";

class UserReportView extends Component {
  state = {
    error: true,
    fetching: true,
    template: {
      title: "Achievement Motivation",
      components: [
        {
          id: 1,
          type: "text",
          title: "Introduction",
          description: ``,
        },
        {
          id: 2,
          type: "chart",
          title: "Chart",
          description: ``,
        },

        {
          id: 3,
          type: "itemlist",
          title: "Traits Explained",
          values: [
            {
              id: 1,
              title: "Performance Approach Goal",
              description: ``,
            },
            {
              id: 2,
              title: "Performance Avoidance Goal",
              description: ``,
            },
            {
              id: 3,
              title: "Mastery Goal",
              description: ``,
            },
          ],
        },
      ],
    },
  };

  componentDidMount = async () => {
    const { response_id } = this.props.match.params;

    try {
      const { data } = await axios.get(`survey/report/${response_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: null,
        },
      });

      console.log(data);
      this.setState({ data, fetching: false, error: false });
    } catch (error) {
      this.setState({ fetching: false, error: true });
    }
  };

  getTemplate = () => {
    const { data } = this.state;

    const { template } = data;

    const anchors = template.components
      .map((component) => component.anchor)
      .filter((anchor) => anchor !== undefined);

    return (
      <div className="main-body">
        <div className="main-nav">
          <nav>
            <div>
              <a className="header" href="#achi">
                {template.title}
              </a>
            </div>
            <ul>
              {anchors.map((anchor) => (
                <li key={anchor}>
                  <a href={`#${anchor}`}>{anchor}</a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div
          id="achi"
          style={{
            background: `url(${img2}), linear-gradient(to left, #7b4397, #dc2430)`,
          }}
        >
          <div className="container h-100">
            <Row className="motiv vh-100">
              <Col className="motiv-col">
                <h1
                  id="achi"
                  style={{
                    fontSize: "100px",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  {template.title}
                </h1>
              </Col>
              {/* <Col className="motiv-col">
        <img src={img1} />
      </Col> */}
            </Row>
          </div>
        </div>

        {template.components.map((component) =>
          component.type === "text" ? (
            <TextView
              anchor={component.anchor}
              key={component.id}
              title={component.title}
              description={component.description}
            />
          ) : component.type === "chart" ? (
            <ChartView
              anchor={component.anchor}
              title={component.title}
              key={component.id}
              data={component.data || []}
              description={component.description}
              chartType={component.chartType}
            />
          ) : component.type === "itemlist" ? (
            <ItemListView
              anchor={component.anchor}
              title={component.title}
              items={component.values}
              key={component.id}
            />
          ) : (
            ""
          )
        )}

        <a href="#" className="return-to-top">
          <i className="fa fa-chevron-up" style={{ fontSize: "20px" }}></i>
        </a>
        <footer>
          <div className="container">
            <p>© 2020 UNSW Sydney</p>
          </div>
        </footer>
      </div>
    );
  };
  render() {
    const { error, fetching } = this.state;

    return (
      <>
        {fetching ? (
          <div>Loading</div>
        ) : error ? (
          <Result
            status="500"
            title="Ooops"
            subTitle="Sorry, something went wrong."
          />
        ) : (
          this.getTemplate()
        )}
      </>
    );
  }
}

export default UserReportView;
