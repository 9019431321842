import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { Table, Button, Input, Icon, notification, Divider } from "antd";

import axios from "axios";

import RoleSelectWiget from "../../wigets/RoleSelectWiget";

import UserResponses from './UserResponses'

class Student extends Component {
  state = {
    dataSource: [],
    onVisibleCreateView: false,
    fetching: true,
    searchText: "",
    visibleUser: false,
    selectedUser: undefined
  };

  componentDidMount = async () => {
    const { data } = await axios.get("admin/user/");
    const dataSource = data.map((user) => ({
      ...user,
      saved: true,
      full_name: `${user.first_name} ${user.last_name}`,
    }));
    this.setState({ dataSource, fetching: false });
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

  handleOnSave = async (zid) => {
    this.setState({ fetching: true });
    const { dataSource } = this.state;
    const index = dataSource.findIndex((user) => user.zid === zid);
    const item = dataSource[index];

    const newData = {
      zid: item.zid,
      is_instructor: item.is_instructor,
      is_staff: item.is_staff,
    };

    try {
      await axios.put("admin/user-role/", newData);

      const newDataSource = [
        ...dataSource.slice(0, index),
        { ...item, saved: true },
        ...dataSource.slice(index + 1),
      ];
      this.openNotificationWithIcon(
        "success",
        "User role updated successfully"
      );
      this.setState({
        dataSource: newDataSource,
        fetching: false,
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  handleOnBlur = (id, role) => {
    const index = this.state.dataSource.findIndex((user) => user.zid === id);
    const item = this.state.dataSource[index];

    let newData = {};

    if (role === "admin") {
      newData = { ...item, is_staff: true, is_instructor: false, saved: false };
    } else if (role === "instructor") {
      newData = { ...item, is_staff: false, is_instructor: true, saved: false };
    } else {
      newData = {
        ...item,
        is_staff: false,
        is_instructor: false,
        saved: false,
      };
    }

    const dataSource = [
      ...this.state.dataSource.slice(0, index),
      newData,
      ...this.state.dataSource.slice(index + 1),
    ];

    this.setState({ dataSource });
  };

  searchEntry(dataSource, searchText) {
    const text = searchText.toLowerCase();
    return dataSource.filter(
      (it) =>
        (!it.zid && !it.first_name && !it.last_name) ||
        (it.zid && it.zid.toLowerCase().includes(text)) ||
        (it.full_name && it.full_name.toLowerCase().includes(text)) ||
        (it.email && it.email.toLowerCase().includes(text))
    );
  }

  TableView = () => {
    const { dataSource, fetching, searchText } = this.state;

    const columns = [
      {
        title: "ZID",
        key: "zid",
        dataIndex: "zid",
        render: (text) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        },
      },
      {
        title: "Name",
        key: "full_name",
        dataIndex: "full_name",
        render: (text, record) => {
          return record.first_name ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        },
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        render: (text, record) => {
          return record.first_name ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        },
      },
      {
        title: "Role",
        key: "role",
        render: (text, record) => (
          <span>
            <RoleSelectWiget
              onBlur={(value) => this.handleOnBlur(record.zid, value)}
              role={
                record.is_staff
                  ? "admin"
                  : record.is_instructor
                  ? "instructor"
                  : "student"
              }
            />
          </span>
        ),
      },
      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <span>
            <Button
              type="link"
              disabled={record.saved}
              onClick={() => this.handleOnSave(record.zid)}
            >
              Save
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={() => {
                this.setState({ visibleUser: true, selectedUser: record })
              }}
            >
              View
            </Button>
          </span>
        ),
      },
    ];

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px" }}>
            Users
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%",
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title, Code"
              onChange={(e) => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />
          </div>
          <Table
            rowKey={(record) => record.zid}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
            pagination={{
              position: "both",
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
            }}
          />
        </div>
      </>
    );
  };
  render() {
    if (this.state.visibleUser) {
      return (
        <UserResponses
          goBack={() => {this.setState({selectedUser: undefined, visibleUser: false})}}
          selectedUser={this.state.selectedUser}
        />
      )
    }
    return this.TableView();
  }
}

export default Student;
