import React, { Component } from "react";
import { Divider } from "antd";

class ThankYou extends Component {
  state = { fetching: true };

  render() {
    // const { groups } = course;
    return (
      <div style={{ backgroundColor: "white" }}>
        <div style={{ padding: "10px" }}>
          <div style={{ display: "flex" }}>
            <img
              style={{ height: "100px" }}
              src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_sydney.png"
              alt="UNSW Logo"
            />

            <div
              style={{
                marginLeft: "20px",
                marginTop: "25px",
                fontSize: "32px",
              }}
            >
              TM
            </div>
          </div>
          <Divider style={{ borderTop: "10px solid #ffe600" }} />
          <div
            style={{
              textAlign: "center",
              background: "rgba(0, 0, 0, 0)",
              borderRadius: "4px",
              marginBottom: "20px",
              padding: "30px 50px",
              margin: " 20px 0",
            }}
          >
            <h1>THANK YOU</h1>
              <p style={{fontSize: 24, fontWeight: "bold", color: "red"}}>Please refresh the previous page!</p>
          </div>
        </div>
      </div>
    );
  }
}
export default ThankYou;
