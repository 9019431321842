import React, { Component } from "react";

import axios from "axios";

class LTILogin extends Component {
  state = {};

  componentDidMount = async () => {
    const tkn = this.props.match.params.lti;

    if (tkn) {
      try {
        const response = await axios.post(
          "auth/lti_validation/",
          { token: tkn },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: null,
            },
          }
        );
        const {
          context_id,
          is_admin,
          is_instructor,
          access,
          refresh,
        } = response.data;
        axios.defaults.headers.common["Authorization"] = `Bearer ${access}`;
        sessionStorage.setItem("context_id", context_id);
        sessionStorage.setItem("isAdmin", is_admin);
        sessionStorage.setItem("isInstructor", is_instructor);
        sessionStorage.setItem("access", access);
        sessionStorage.setItem("refresh", refresh);

        this.props.history.push("/moodle-course/" + context_id);
      } catch {
        this.props.history.push("/forbidden");
      }
    } else {
      this.props.history.push("/");
    }
  };
  render() {
    return (
      <div
        style={{
          alignItems: "center",
          width: "100%",
          height: "100%",
          background: "rgb(49,7,49)",
        }}
      ></div>
    );
  }
}

export default LTILogin;
