import React, { Component } from "react";
import {
  Spin,
  Button,
  Icon,
  Tag,
  Card,
  Row,
  Col,
  notification,
  Result,
} from "antd";
import axios from "axios";

const TMLogo = require("../img/icons/TMP_logo_large.jpg");

class MoodleCourse extends Component {
  state = { fetching: true, errorMessage: undefined };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

  componentDidMount = async () => {
    const { id: moddleID } = this.props.match.params;
    let errorMessage = undefined;

    try {
      const { data: course } = await axios.get(`/course/${moddleID}/`);
      let { learner_id, moodle_id } = course;

      const isAdmin = sessionStorage.getItem("isAdmin") === "true";
      const isInstructor = sessionStorage.getItem("isInstructor") === "true";

      learner_id = learner_id.substring(1);
      this.setState({
        course,
        fetching: false,
        learner_id,
        moodle_id,
        isAdmin,
        isInstructor,
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        errorMessage = error.response.data.message;

        this.setState({ error });
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        errorMessage = error.request;
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        errorMessage = error.request;
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      this.setState({ fetching: false, errorMessage });
      console.log(error);
    }
  };

  startProgress = async (surveyId) => {
    const { data: dataSource } = await axios.post("/survey/startSurvey/", {
      courseId: this.state.course.id,
      surveyId: surveyId,
    });
    console.log(dataSource);
  };

  goToSurvey = async (survey) => {
    // check if survey already completed.
    const zid = this.state.learner_id
    const course_code = this.state.course.id
    const survey_application_id = survey.survey
    const { data: surveyResponse } = await axios.get(`/survey/get_survey_response?zid=${zid}&cid=${course_code}&survey_application_id=${survey_application_id}`)
    if (surveyResponse) {
      let button = (<Button onClick={() => window.location.reload()} type="primary" size="small">Refresh</Button>)
      this.openNotificationWithIcon(
        "error",
        "Survey has already been completed - Refresh page to see status changes",
        button
      );
    } else {
      this.startProgress(survey.survey);
      window.open(`${survey.url}&sid=${this.state.learner_id}&cid=${this.state.course.code}&ctxid=${this.state.moodle_id}`)
    }
  }

  render() {
    const {
      course,
      fetching,
      learner_id,
      moodle_id,
      isAdmin,
      isInstructor,
      errorMessage,
    } = this.state;

    const statusMap = {
      COMPLETED: { label: "Completed", color: "green" },

      NOT_STARTED: {
        label: "Not Started",
        color: "red",
      },

      IN_PROGRESS: {
        label: "In Progress",
        color: "orange",
      },
    };

    

    const iconComponent = <img src={TMLogo} width="150" />;

    // const { groups } = course;
    return (
      <div style={{ backgroundColor: "white" }}>
        <div style={{ padding: "10px" }}>
          <div>
            {fetching ? (
              <div
                style={{
                  textAlign: "center",
                  background: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  marginBottom: "20px",
                  padding: "30px 50px",
                  margin: " 20px 0",
                }}
              >
                <Spin />
              </div>
            ) : errorMessage ? (
              <Result
                status="404"
                title={errorMessage}
                subTitle="Sorry, the course you visited does not exist. Please contact course admin"
                extra={<Button type="primary">Back Home</Button>}
              />
            ) : (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ padding: "20px", minWidth: "400px", width: "80%" }}
                >
                  <Card>
                    <div>{iconComponent}</div>
                    <div className="description" style={{ padding: 10 }}>
                      <h1 style={{marginLeft: 22}}>{`${course.code} - ${course.title}`}</h1>
                      <div
                        style={{marginBottom: 30, marginLeft: 22}}
                        dangerouslySetInnerHTML={{ __html: course.description }}
                      />
                    </div>
                    <div style={{flex: 1, display: "flex", alignItems: "start", justifyContent: "center", flexDirection: "column"}}>
                    {course.groups.map((group) => {
                      return (
                        <div key={group.id} style={{width: "60%"}}>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: 20,
                              borderBottom: "2px solid #2a9d8f",
                              marginLeft: 20
                            }}
                          >
                            {group.title}
                          </p>
                          <div>
                            {group.surveys.map((survey) => (
                              <div
                                key={survey.id}
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  padding: 10,
                                  flexDirection: "column",
                                  marginLeft: 24
                                }}
                              >
                                <Row>
                                  <Col span={8}>
                                    <p>{survey.title}</p>
                                  </Col>
                                  <Col span={8}>
                                    <span>
                                      <Tag
                                        color={statusMap[survey.status].color}
                                      >
                                        {statusMap[survey.status].label}
                                      </Tag>
                                    </span>
                                  </Col>
                                  <Col span={8}>
                                    {survey.status !== "COMPLETED" ? (
                                      <span style={{ textAlign: "center" }}>
                                          <Button
                                            onClick={() => {
                                              this.goToSurvey(survey)
                                            }}
                                          >
                                            <Icon type="link" />
                                            Go to survey
                                          </Button>
                                      </span>
                                    ) : (
                                      "N/A"
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                    </div>
                  </Card>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default MoodleCourse;
