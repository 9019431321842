import React, { Component } from "react";

import { Form, Input, Button, Col, Row, Popconfirm, Select, Tag } from "antd";

class TemplateLookupForm extends Component {
  state = {
    htmlPreview: "",
    oldMoodleId: "",
    newMoodleId: "",
    members: [],
    currentSurveyScales: [],
    currentTransformers: [],
  };

  handleSubmit = (e) => {
    console.log(e);
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log({ ...values });
        this.props.onAddTemplateLookup({
          ...values,
        });
      }
    });
  };

  onSelected = (id) => {
    const { surveys } = this.props;

    const currentSurvey = surveys.find((survey) => survey.id === id);
    const currentSurveyScales = currentSurvey.scales;
    const currentTransformers = currentSurvey.transformers;

    this.setState({ currentSurveyScales, currentTransformers });
  };

  componentDidMount = () => {
    const { selectedTemplateLookup, surveys } = this.props;

    if (selectedTemplateLookup) {
      const currentSurvey = surveys.find(
        (survey) => survey.id === selectedTemplateLookup.survey.id
      );
      const currentSurveyScales = currentSurvey.scales || [];
      const currentTransformers = currentSurvey.transformers;

      this.setState({
        currentSurveyScales,
        currentTransformers,
        htmlPreview: selectedTemplateLookup.text,
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedTemplateLookup, surveys } = this.props;
    const {
      currentSurveyScales,
      htmlPreview,
      currentTransformers,
    } = this.state;

    return (
      <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Survey">
              {getFieldDecorator("survey", {
                initialValue: selectedTemplateLookup
                  ? selectedTemplateLookup.survey.id
                  : "",

                rules: [{ required: true, message: "Please select Survey" }],
              })(
                <Select onChange={this.onSelected} optionFilterProp="name">
                  {surveys.map((term) => (
                    <Select.Option
                      key={term.id}
                      value={term.id}
                      name={term.label}
                    >
                      {term.title}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Code">
              {getFieldDecorator("code", {
                initialValue: selectedTemplateLookup
                  ? selectedTemplateLookup.code
                  : "",
                rules: [
                  { required: true, message: "Please enter template Tile" },
                ],
              })(<Input placeholder="Please enter template title" />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={4}>Available Scales</Col>
          <Col span={20}>
            :{" "}
            {currentSurveyScales &&
              currentSurveyScales.map((scale) => (
                <Tag color="green" key={scale.ID}>
                  {scale.Name}
                </Tag>
              ))}
          </Col>
        </Row>
        <br />

        <Row>
          <Col span={4}>Available Transformers</Col>
          <Col span={20}>
            :{" "}
            {currentTransformers &&
              currentTransformers.map((scale) => (
                <Tag style={{ margin: "2px" }} color="red" key={scale.id}>
                  {scale.code}
                </Tag>
              ))}
          </Col>
        </Row>
        <br />

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Text">
              {getFieldDecorator("text", {
                initialValue: selectedTemplateLookup
                  ? selectedTemplateLookup.text
                  : "",
                rules: [],
              })(
                <Input.TextArea
                  rows={10}
                  onChange={(e) => {
                    this.setState({ htmlPreview: e.target.value });
                  }}
                  placeholder="please enter text"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            Preview
            <div
              style={{
                height: "220px",
                border: "2px black solid",
                marginBottom: 20,
                marginTop: 9,
                padding: 10,
              }}
              dangerouslySetInnerHTML={{
                __html: htmlPreview,
              }}
            ></div>
          </Col>
        </Row>

        <Form.Item wrapperCol={{ offset: 16 }}>
          <Popconfirm
            title="Sure to cancel?"
            onConfirm={() => this.props.onCancel()}
          >
            <Button style={{ marginRight: "10px" }} type="danger">
              Cancel
            </Button>
          </Popconfirm>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: "register" })(TemplateLookupForm);
