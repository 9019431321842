import React from "react";
import img2 from "./resources/bg-pattern.png";
import { Row } from "antd";
import { Rose, Bar } from "@ant-design/charts";
function ChartView({ title, description, anchor, chartType, data }) {
  return (
    <div className="chart" id={anchor}>
      <div className="container">
        <h1
          style={{
            fontSize: "50px",
            textAlign: "center",
          }}
        >
          {title}
        </h1>
        <hr />
        <Row className="intro-cont">
          <br />
          <br />
          {chartType === "rose" ? (
            <RoseChart data={data} />
          ) : chartType === "barrange" ? (
            <BarRangeChart data={data} />
          ) : chartType === "bar" ? (
            <BarChart data={data} />
          ) : (
            ""
          )}

          <p
            style={{
              fontSize: "20px",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            {description}
          </p>
        </Row>
      </div>
    </div>
  );
}

function RoseChart({ data }) {
  const config = {
    data,
    height: 400,
    xField: "type",
    yField: "value",
    seriesField: "type",
  };

  return <Rose {...config} />;
}

function BarRangeChart({ data }) {
  const config = {
    data,
    height: 400,
    yField: "type",
    xField: "value",
    isRange: true,
  };

  return <Bar {...config} />;
}

function BarChart({ data }) {
  const config = {
    data,
    height: 400,
    yField: "type",
    xField: "value",
    color: ({ type }) => {
      return type === "UK" ? "red" : "#1890ff";
    },
  };

  return <Bar {...config} />;
}

export default ChartView;
