import React, { useState } from "react";

import { Tag, Select } from "antd";

const RoleSelectWiget = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [role, setRole] = useState(props.role);
  const mappingDict = {
    admin: { label: "Admin", color: "red" },
    student: { label: "Student", color: "green" },
    instructor: { label: "Instructor", color: "blue" },
  };
  const currentTag = mappingDict[props.role];

  return (
    <div>
      {editMode ? (
        <Select
          value={role}
          style={{ width: "100px" }}
          autoFocus
          onChange={(val) => setRole(val)}
          onBlur={() => {
            setEditMode(false);
            props.onBlur(role);
          }}
        >
          <Select.Option key="admin">Admin</Select.Option>
          <Select.Option key="student">Student</Select.Option>
          <Select.Option key="instructor">Instructor</Select.Option>
        </Select>
      ) : (
        <Tag onClick={() => setEditMode(true)} color={currentTag.color}>
          {currentTag.label}
        </Tag>
      )}
    </div>
  );
};

export default RoleSelectWiget;
