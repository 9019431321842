import React, { Component } from "react";
import Highlighter from "react-highlight-words";

import {
  Table,
  Button,
  Divider,
  Popconfirm,
  Input,
  Icon,
  notification,
  Tooltip,
} from "antd";

import axios from "axios";

import CourseForm from "./CourseForm";
import CourseExport from './CourseExport'

class Course extends Component {
  state = {
    dataSource: [],
    groups: [],
    terms: [],
    onVisibleCreateView: false,
    fetching: true,
    searchText: "",
    onDataExportView: false,
  };

  componentDidMount = async () => {
    const { data: dataSource } = await axios.get("course/");
    const { data: groups } = await axios.get("course/group/");
    const { data: terms } = await axios.get("admin/term/");

    console.log(dataSource);
    this.setState({ dataSource, groups, terms, fetching: false });
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };
  handleOnAddCourse = async (course) => {
    const { selectedRow } = this.state;
    this.setState({ fetching: true });
    const newCourse = { ...selectedRow, ...course };

    try {
      const response = selectedRow
        ? await axios.put("course/", newCourse)
        : await axios.post("course/", newCourse);
      const index = this.state.dataSource.findIndex(
        (record) => record.id === response.data.id
      );

      if (selectedRow) {
        this.openNotificationWithIcon("success", "Record updated successfully");
      } else {
        this.openNotificationWithIcon("success", "Record created successfully");
      }

      const dataSource =
        index < 0
          ? [...this.state.dataSource, response.data]
          : [
              ...this.state.dataSource.slice(0, index),
              response.data,
              ...this.state.dataSource.slice(index + 1),
            ];
      this.setState({
        dataSource,
        onVisibleCreateView: false,
        fetching: false,
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      this.setState({ fetching: false });
      console.log(error);
    }
  };

  handleOnDeleteCourse = async (id) => {
    try {
      await axios.delete("course/", { data: { id } });
      const dataSource = this.state.dataSource.filter(
        (record) => record.id !== id
      );

      this.setState({ dataSource });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
    }
  };

  searchEntry(dataSource, searchText) {
    const text = searchText.toLowerCase();
    return dataSource.filter(
      (it) =>
        (!it.title && !it.code) ||
        (it.title && it.title.toLowerCase().includes(text)) ||
        (it.code && it.code.toLowerCase().includes(text))
    );
  }


  TableView = () => {
    const { dataSource, fetching, searchText, terms, groups } = this.state;

    const columns = [
      {
        title: "Code",
        key: "code",
        dataIndex: "code",
        sorter: (a, b) => {
          if (a.code < b.code) return 1
          else if (a.code > b.code) return -1
          return 0
        },
        render: (text) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        },
      },
      {
        title: "Title",
        key: "title",
        dataIndex: "title",
        sorter: (a, b) => {
          if (a.title < b.title) return 1
          else if (a.title > b.title) return -1
          return 0
        },
        render: (text) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        },
      },
      {
        title: "Term",
        key: "term",
        dataIndex: "term",
        filters: [
          ...terms.map((term) => ({
            text: term.description,
            value: term.description,
          })),
        ],
        onFilter: (value, record) => record.term.indexOf(value) === 0,
      },
      {
        title: "No Of Groups",
        key: "groups",
        dataIndex: "groups",
        render: (text, record) => {
          const currentGroups = groups.filter((group) =>
            text.includes(group.id)
          );
          return (
            <span>
              {text.length ? (
                <Tooltip
                  placement="topLeft"
                  title={currentGroups.map((group) => (
                    <div key={group.id}>{`${group.title},`} </div>
                  ))}
                >
                  <Button type="link">{text.length}</Button>
                </Tooltip>
              ) : (
                <Button type="link">{text.length}</Button>
              )}
            </span>
          );
        },
      },
      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <span>
            <a
              disabled={!record.moodle_id}
              href={`moodle-course/${record.moodle_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={record.saved}
              onClick={() =>
                this.setState({
                  selectedRow: record,
                  onVisibleCreateView: true,
                })
              }
            >
              Edit
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={record.saved}
              onClick={() =>
                this.setState({
                  selectedRow: record,
                  onDataExportView: true,
                })
              }
            >
              Export
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleOnDeleteCourse(record.id)}
            >
              <Button disabled={!record.is_deletable} type="link">
                Delete
              </Button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px" }}>
            Courses
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%",
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title, Code"
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />

            <>
              <Button
                style={{ marginLeft: "10px", float: "right" }}
                type="primary"
                onClick={() => {
                  this.setState({
                    onVisibleCreateView: true,
                    selectedRow: undefined,
                  });
                }}
              >
                Create New
              </Button>
            </>
          </div>
          <Table
            rowKey={(record) => record.id}
            bordered
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
            loading={fetching}
            pagination={{
              position: "both",
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
              defaultPageSize: this.getPageSize()
            }}
            onChange={(e) => this.setPageSize(e.pageSize)}
          />
        </div>
      </>
    );
  };

  getPageSize = () => {
    const size = localStorage.getItem("tm-course-pageSize")
    if (size) return parseInt(size)
    return 10
  }

  setPageSize = (pageSize) => {
    localStorage.setItem("tm-course-pageSize", pageSize)
  }
  
  render() {
    const { onVisibleCreateView, onDataExportView, groups, terms } = this.state;
    if (onVisibleCreateView) return (
      <CourseForm
        groups={groups}
        terms={terms}
        onAddcourse={this.handleOnAddCourse}
        onVisibleCreateView={this.state.onVisibleCreateView}
        selectedCourse={this.state.selectedRow}
        onCancel={() => this.setState({ onVisibleCreateView: false })}
      />
    ) 
    else if (onDataExportView) {
      return <CourseExport selectedCourse={this.state.selectedRow} onCancel={() => {this.setState({onDataExportView: false})}}/>
    }
    else {
      return this.TableView()
    }
  }
}

export default Course;
