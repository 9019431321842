import React, { Component, Suspense } from "react";
import { Switch, Route, NavLink, Redirect, withRouter } from "react-router-dom";
import { Button, Layout, Menu } from "antd";
import { authenticatedApplication } from "react-msal-jwt";
import { LandingPage } from "login-landing-page";
import axios from "axios";
import preval from "preval.macro";

import Forbidden from "./error/Forbidden";
import PageNotFound from "../src/error/404";
import ServerError from "../src/error/ServerError";

import Course from "./components/admin/course/Courses";
import CourseView from "./components/admin/course/CourseView";
import Terms from "./components/admin/term/Terms";
import User from "./components/admin/user/User";
import Group from "./components/admin/group/Groups";
import Survey from "./components/admin/survey/SurveyApplication";
import LTILogin from "./components/LTILogin";
import MoodleCourse from "./components/MoodleCourse";
import ThankYou from "./components/ThankYou";

import TemplateManager from "./components/admin/template/TemplateManager";

import UserReportView from "./components/admin/report/UserReportView";

import AppContext from "./AppContext";

import "./App.css";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

axios.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("access")}`;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: props.isAdmin || sessionStorage.getItem("isAdmin") === "true",
      isInstructor:
        props.isInstructor || sessionStorage.getItem("isInstructor") === "true",
    };

    // If mounting the component on /error or /forbidden routes,
    // then redirect to the root route
    if (["/error", "/forbidden"].includes(props.location.pathname))
      props.history.replace("/");

    // Intercept requests to detect whether the access token is still valid
    axios.interceptors.request.use(
      async (config) => {
        const {
          access: hasAccessToken,
          refresh: hasRefreshToken,
        } = props.isTokenExpired();

        // If the access token is invalid, and we are not interacting with auth endpoints,
        // then renew the access token
        if (
          !hasAccessToken &&
          !["auth/login/", "auth/refresh/", "auth/error/"].includes(config.url)
        ) {
          if (hasRefreshToken) {
            const accessToken = await props.refreshAccessToken();
            config.headers.common["Authorization"] = `Bearer ${accessToken}`;
          } else {
            props.throwTokenError();
          }
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  render() {
    const { getAzureToken, logout, location } = this.props;
    const { isAdmin, isInstructor } = this.state;

    const routeKey = location.pathname.split("/")[1];

    return (
      <AppContext.Provider
        value={{ getAzureToken, isAdmin, isInstructor, logout }}
      >
        {!(isAdmin || isInstructor) ? (
          <Redirect to="/forbidden" />
        ) : (
          <Layout style={{ height: "100%", backgroundColor: "rgb(49,7,49)" }}>
            <Layout.Sider>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  backgroundColor: "rgb(49,7,49)",
                }}
              >
                <img
                  className="logo"
                  src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
                  alt="UNSW Logo"
                  style={{ width: "80%", margin: 20 }}
                />

                <div style={{ flex: 1 }}>
                  <Menu
                    theme="dark"
                    mode="inline"
                    defaultOpenKeys={["manage", "advance"]}
                    selectedKeys={[routeKey]}
                    style={{
                      backgroundColor: "rgb(49,7,49)",
                    }}
                  >
                    <Menu.SubMenu title="Manage Resources" key="manage">
                      <Menu.Item key="course">
                        <NavLink to="/course">Courses</NavLink>
                      </Menu.Item>
                      <Menu.Item key="group">
                        <NavLink to="/group">Groups</NavLink>
                      </Menu.Item>
                      <Menu.Item key="survey">
                        <NavLink to="/survey">Surveys</NavLink>
                      </Menu.Item>
                    </Menu.SubMenu>
                    {isAdmin && (
                      <Menu.SubMenu title="Admin" key="advance">
                        <Menu.Item key="term">
                          <NavLink to="/term">Terms</NavLink>
                        </Menu.Item>
                        <Menu.Item key="user">
                          <NavLink to="/user">User</NavLink>
                        </Menu.Item>
                        <Menu.Item key="report">
                          <NavLink to="/report">Report</NavLink>
                        </Menu.Item>
                        <Menu.Item key="template">
                          <NavLink to="/template">Templates</NavLink>
                        </Menu.Item>
                      </Menu.SubMenu>
                    )}
                  </Menu>
                </div>

                <div
                  style={{
                    textAlign: "center",
                    color: "rgba(160, 160, 160, 0.75)",
                    margin: "20px 0",
                  }}
                >
                  {`Build date: ${preval`
                const moment = require("moment");
                module.exports = moment().format("DD/MM/YYYY");
              `}`}
                </div>
              </div>
            </Layout.Sider>

            <Layout>
              <Layout.Header
                style={{
                  background: "rgb(49, 7, 49)",
                  padding: "0 2px",
                }}
              >
                <h1
                  style={{
                    marginLeft: 20,
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  TM
                  <Button
                    type="warning"
                    icon="poweroff"
                    onClick={logout}
                    style={{
                      float: "right",
                      marginTop: "15px",
                      marginRight: "1%",
                    }}
                  >
                    Logout
                  </Button>
                  <div
                    style={{
                      float: "right",
                      marginTop: "15px",
                      marginRight: "2%",
                    }}
                  />
                </h1>
              </Layout.Header>

              <Layout.Content
                style={{
                  margin: "1px 16px 0",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div style={{ padding: 24, background: "#fff", flex: "1" }}>
                  <Switch>
                    <Redirect exact from="/" to="/course" />

                    <Suspense fallback={<div>Loading...</div>}>
                      <Route exact path="/course/:id" component={CourseView} />
                      <Route exact path="/course" component={Course} />
                      <Route exact path="/group" component={Group} />
                      <Route exact path="/survey" component={Survey} />
                      <Route
                        exact
                        path="/report"
                        component={() => <div>Coming soon</div>}
                      />

                      {isAdmin && (
                        <Route exact path="/term" component={Terms} />
                      )}
                      {isAdmin && <Route exact path="/user" component={User} />}
                      {isAdmin && (
                        <Route
                          exact
                          path="/template"
                          component={TemplateManager}
                        />
                      )}
                    </Suspense>
                  </Switch>
                </div>
              </Layout.Content>
            </Layout>
          </Layout>
        )}
      </AppContext.Provider>
    );
  }
}

export default () => (
  <Switch>
    <Route path="/forbidden" component={Forbidden} />
    <Route path="/error" component={ServerError} />
    <Route path="/404" component={PageNotFound} />
    <Route path="/lti/:lti" component={LTILogin} />
    <Route path="/moodle-course/:id" component={MoodleCourse} />
    <Route path="/result/:id" component={ThankYou} />
    <Route path="/report/:response_id" component={UserReportView} />
    <Route path="/" component={loginPage} />
  </Switch>
);
const loginPage = authenticatedApplication({
  landingPage: (
    <LandingPage
      title="TM 2"
      background="https://cdn.teaching.unsw.edu.au/cover-imges/dan-freeman-7Zb7kUyQg1E-unsplash.jpg"
      logo={
        <a href="https://www.unsw.edu.au/">
          <img
            src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
            alt="UNSW Logo"
          />
        </a>
      }
      footerItems={[
        <a href="mailto:contact.pvce@unsw.edu.au">Contact us</a>,
        <a href="https://www.unsw.edu.au/privacy">Privacy Policy</a>,
        <a href="https://www.unsw.edu.au/copyright-disclaimer">
          Copyright &amp; Disclaimer
        </a>,
        <span style={{ color: "rgba(117, 117, 117, 0.5)" }}>
          {`Build date: ${preval`
        const moment = require("moment");
        module.exports = moment().format("DD/MM/YYYY");
      `}`}
        </span>,
      ]}
    />
  ),
  msalConfig: {
    auth: {
      clientId: process.env.REACT_APP_AZURE_APP_ID,
      authority: process.env.REACT_APP_AZURE_AUTHORITY,
      redirectUri: process.env.REACT_APP_FRONTEND_URL,
    },
  },
  onAuthSuccess: async (azureIdToken, azureAccessToken) => {
    const headers = {
      "Content-Type": "application/json; charset=utf8",
      Authorization: "Token " + azureIdToken,
    };
    const response = await axios.post(
      "auth/login/",
      { accessToken: azureAccessToken },
      { headers }
    );

    const data = response.data;
    axios.defaults.headers.common["Authorization"] = `Bearer ${data.access}`;
    sessionStorage.setItem("isAdmin", data.is_admin);
    sessionStorage.setItem("isInstructor", data.is_instructor);

    return {
      accessToken: data.access,
      refreshToken: data.refresh,
      extras: {
        isAdmin: data.isAdmin,
        isInstructor: data.isInstructor,
      },
    };
  },
  onAuthError: (error) => {
    const { errorCode } = error;

    if (errorCode === "user_cancelled" || errorCode === "access_denied")
      return { type: "warning", message: "Login popup was closed." };
    else if (errorCode === "login_progress_error")
      return { type: "warning", message: "Login popup is already open." };
    else if (errorCode === "popup_window_error")
      return {
        type: "warning",
        message:
          "Error opening popup window. This can happen if you are using IE or if popups are blocked in the browser.",
      };
    else if (error.message === "Network Error")
      return {
        type: "error",
        message: (
          <>
            Failed to communicate with the server. If the issue persists, please{" "}
            <a href="mailto:contact.pvce@unsw.edu.au">contact support</a>.
          </>
        ),
      };

    const payload = {
      userAgent: window.navigator.userAgent,
      name: error.name,
      code: errorCode,
      message: error.message,
      stack: error.stack.toString().split("\n"),
    };
    const headers = {
      "Content-Type": "application/json; charset=utf8",
      common: { Authorization: null },
    };
    axios.post("auth/error/", payload, { headers });

    return {
      type: "error",
      message: (
        <>
          An issue occurred while logging you in. Please try again, ensuring
          that you use <strong>{`<Your zID>`}@ad.unsw.edu.au</strong> to log in.
          If the issue persists, please{" "}
          <a href="mailto:contact.pvce@unsw.edu.au">contact support</a>.
        </>
      ),
    };
  },
  refreshAccess: async (refresh) => {
    const response = await axios.post("auth/refresh/", { refresh });

    const data = response.data;
    axios.defaults.headers.common["Authorization"] = `Bearer ${data.access}`;

    return data.access;
  },
  tokenCheckFrequency: 2,
})(withRouter((props) => <App {...props} />));
