import React, { useState, useEffect } from "react";

import { Table, Alert } from "antd";

import axios from "axios";

const SurveyGroupDetailed = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [groups, setGroups] = useState([]);

  const getdata = async (survey_id) => {
    try {
      const { data } = await axios.get(`survey/group/${survey_id}`);
      setGroups(data);
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    setError(false);
    getdata(props.survey_id);
  }, [props.survey_id]);

  const columns = [
    {
      title: "Course (Term)",
      key: "title",
      dataIndex: "code",
      render: (text, record) => {
        return <div>{`${text} (${record.term})`}</div>;
      },
    },
    {
      title: "Groups",
      key: "groups",
      dataIndex: "groups",
      render: (text) => {
        return (
          <div>
            {text.map((group, i) => (
              <span key={i}>
                {`${group.title}${i !== text.length - 1 ? ", " : ""}`}
              </span>
            ))}
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <h3 style={{ margin: "0px 0px 10px" }}>
        Courses associated with the Survey
      </h3>
      <Table
        rowKey={(row) => row.id}
        bordered
        columns={columns}
        loading={loading}
        dataSource={groups}
        pagination={{
          pageSize: 5,
          position: "bottom",
          pageSizeOptions: ["5", "10", "20"],
          showSizeChanger: true,
        }}
      />
      {error ? (
        <Alert
          message="Error"
          description="Error Occured"
          type="error"
          showIcon
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default SurveyGroupDetailed;
