import React, { Component } from "react";

import Highlighter from "react-highlight-words";

import {
  Table,
  Button,
  Divider,
  Popconfirm,
  Input,
  Icon,
  notification,
} from "antd";

// import TemplateGenerator from "./TemplateGenerator";

import TemplateForm from "./TemplateForm";

import axios from "axios";

class Template extends Component {
  state = {
    dataSource: [],
    fetching: true,
    searchText: "",
    onVisibleCreateView: false,
    surveys: [],
  };

  componentDidMount = async () => {
    const { data: dataSource } = await axios.get("survey/report-template/");

    const { data: surveys } = await axios.get("survey/");

    console.log(surveys);

    this.setState({ dataSource, fetching: false, surveys });
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

  handleOnAddTemplate = async (course) => {
    const { selectedRow } = this.state;
    this.setState({ fetching: true });
    const newTemplate = { ...selectedRow, ...course };

    try {
      const response = selectedRow
        ? await axios.put("survey/report-template/", newTemplate)
        : await axios.post("survey/report-template/", newTemplate);
      const index = this.state.dataSource.findIndex(
        (record) => record.id === response.data.id
      );

      if (selectedRow) {
        this.openNotificationWithIcon("success", "Record updated successfully");
      } else {
        this.openNotificationWithIcon("success", "Record created successfully");
      }

      const dataSource =
        index < 0
          ? [...this.state.dataSource, response.data]
          : [
              ...this.state.dataSource.slice(0, index),
              response.data,
              ...this.state.dataSource.slice(index + 1),
            ];
      this.setState({
        dataSource,
        onVisibleCreateView: false,
        fetching: false,
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      this.setState({ fetching: false });
      console.log(error);
    }
  };

  handleOnDeleteTemplate = async (id) => {
    try {
      await axios.delete("survey/report-template/", { data: { id } });
      const dataSource = this.state.dataSource.filter(
        (record) => record.id !== id
      );

      this.setState({ dataSource });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
    }
  };

  searchEntry(dataSource, searchText) {
    const text = searchText.toLowerCase();
    return dataSource.filter(
      (it) =>
        (!it.title && !it.survey.title) ||
        (it.title && it.title.toLowerCase().includes(text)) ||
        (it.survey.title && it.survey.title.toLowerCase().includes(text))
    );
  }

  getTemplateList = () => {
    const { dataSource, fetching, searchText } = this.state;

    const columns = [
      {
        title: "Title",
        key: "title",
        dataIndex: "title",
        sorter: (a, b) => {
          if (a.title < b.title) return 1;
          else if (a.title > b.title) return -1;
          return 0;
        },
        render: (text) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        },
      },
      {
        title: "Survey",
        key: "survey",
        dataIndex: "survey.title",
        sorter: (a, b) => {
          if (a.code < b.code) return 1;
          else if (a.code > b.code) return -1;
          return 0;
        },
        render: (text) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        },
      },

      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <span>
            <Button
              type="link"
              disabled={record.saved}
              onClick={() =>
                this.setState({
                  selectedRow: record,
                  onVisibleCreateView: true,
                })
              }
            >
              Edit
            </Button>
            <Divider type="vertical" />

            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleOnDeleteTemplate(record.id)}
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px" }}>
            Templates
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%",
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title, Survey"
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />

            <>
              <Button
                style={{ marginLeft: "10px", float: "right" }}
                type="primary"
                onClick={() => {
                  this.setState({
                    onVisibleCreateView: true,
                    selectedRow: undefined,
                  });
                }}
              >
                Create New
              </Button>
            </>
          </div>
          <Table
            rowKey={(record) => record.id}
            bordered
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
            loading={fetching}
            pagination={{
              position: "both",
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
            }}
            onChange={(e) => this.setPageSize(e.pageSize)}
          />
        </div>
      </>
    );
  };
  render() {
    const { onVisibleCreateView, surveys } = this.state;
    return (
      <>
        {onVisibleCreateView ? (
          <TemplateForm
            surveys={surveys}
            onAddtemplate={this.handleOnAddTemplate}
            onVisibleCreateView={this.state.onVisibleCreateView}
            selectedTemplate={this.state.selectedRow}
            onCancel={() => this.setState({ onVisibleCreateView: false })}
          />
        ) : (
          this.getTemplateList()
        )}
      </>
    );
  }
}

export default Template;
