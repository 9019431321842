import React, { Component } from "react";

import { Form, Input, Button, Drawer, Col, Row, Popconfirm } from "antd";
import SurveyGroupDetailed from "./SurveyGroupDetailed";

class ApplicationForm extends Component {
  state = {};

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //this.props.form.resetFields();
        this.props.onAddStudent({ ...values }, this.props.form);
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedApplication, loading } = this.props;

    return (
      <Drawer
        title={
          selectedApplication ? "Edit Application" : "Create a new Application"
        }
        width={500}
        closable={false}
        onClose={this.onClose}
        visible={this.props.onVisibleCreateView}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Title">
                {getFieldDecorator("title", {
                  initialValue: selectedApplication
                    ? selectedApplication.title
                    : "",
                  rules: [{ required: true, message: "Please enter title" }],
                })(<Input placeholder="Please enter title" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Survey ID">
                {getFieldDecorator("survey_id", {
                  initialValue: selectedApplication
                    ? selectedApplication.survey_id
                    : "",
                  rules: [
                    { required: true, message: "Please enter survey id" },
                  ],
                })(<Input placeholder="Please enter survey id" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Description">
                {getFieldDecorator("description", {
                  initialValue: selectedApplication
                    ? selectedApplication.description
                    : "",
                })(
                  <Input.TextArea
                    rows={7}
                    placeholder="Please enter description"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          {selectedApplication ? (
            <Row gutter={16}>
              <Col span={24}>
                <SurveyGroupDetailed survey_id={selectedApplication.id} />
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Form.Item wrapperCol={{ offset: 14 }}>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => this.props.onCancel()}
            >
              <Button
                style={{ marginRight: "10px" }}
                type="danger"
                loading={loading}
              >
                Cancel
              </Button>
            </Popconfirm>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    );
  }
}

export default Form.create({ name: "register" })(ApplicationForm);
