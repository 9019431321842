import React, { Component } from "react";
import axios from "axios";

import { Button, Checkbox, Table, Divider } from "antd";
import CourseExportModal from "./modals/CourseExportModal";
const { Parser } = require("json2csv");

class CourseExport extends Component {
  state = {
    dataSource: [],
    columns: [],
    selectedColumns: [],
    visibleExportModal: false,
    loading: true,
  };

  componentDidMount = async () => {
    // const { data: course } = await axios.get(`/course/${this.props.selectedCourse.moodle_id}`);
    this.makeRequest();
  };

  makeRequest = async () => {
    const { data: dataSource } = await axios.get(
      `/course/get_responses/?moodleId=${this.props.selectedCourse.moodle_id}`
    );
    const { data: surveys } = await axios.get(
      `/course/get_course_surveys/?moodleId=${this.props.selectedCourse.moodle_id}`
    );
    let surveyCategories = this.getColumns(surveys);
    // const filtered = dataSource.filter(response => Object.keys(response.raw_response) != 0)
    // const another = dataSource.filter(response => response.survey_id == "SV_6EG6uxKiaIAQoTP")
    // console.log("another", another)
    this.transformData(dataSource, surveyCategories);
  };

  getColumns = (surveys) => {
    // console.log("surveys", surveys)
    let surveyCategories = {};
    // looks something like { survey_id: "", categories: [{id, title}]}
    // first lets get the scoring if they exist

    // for (let i = 0; i < surveys.length; i++) {
    //   const title = surveys[i].title;
    //   if (Object.keys(surveys[i].survey_data) != 0) {
    //     let data = surveys[i].survey_data;
    //     if (data.meta.httpStatus == "200 - OK") {
    //       if (data.result.Scoring != undefined) {
    //         // scoring exists for this survey!
    //         let object = {
    //           surveyId: surveys[i].survey_id,
    //           title: title,
    //           categories: data.result.Scoring.ScoringCategories,
    //         };
    //         surveyCategories[surveys[i].survey_id] = object;
    //       } else continue;
    //     } else continue;
    //   } else continue;
    // }
    // let surveyCategories2 = {};

    // console.log(surveys);

    surveys.forEach((survey) => {
      if (survey.is_survey_data_fetched) {
        surveyCategories[survey.survey_id] = {
          surveyId: survey.survey_id,
          title: survey.title,
          categories: survey.scales,
        };
      }
    });

    // console.log(surveyCategories, surveyCategories2);
    return surveyCategories;
  };

  createOptions = (columns) => {
    // console.log(columns)
    let options = columns.map((col) => {
      return {
        label: col,
        value: col,
        defaultChecked: true,
      };
    });
    return options;
  };

  transformData = (data, surveyCategories) => {
    /*
        format required: [
            {
                zid: z5019338,
                survey_1_1: score
                ...
            }
        ]
      */
    // get all columns
    console.log("records", data[0]);
    let columnDict = {};
    let zids = {};
    data.forEach((row) => {
      // if (!columnDict[row.survey_id]) columnDict[row.survey_id] = row.survey_title
      // now make the object
      // scores will be named like this: Survey_title - score_title
      if (row.survey_id == "SV_40jV3Kko7veFeVT") {
        if (!columnDict["SV_40jV3Kko7veFeVT"])
          columnDict["SV_40jV3Kko7veFeVT-Complete"] = true;
        if (!zids[row.learner]) zids[row.learner] = {};
        zids[row.learner]["SV_40jV3Kko7veFeVT-Complete"] = true;
      } else if (Object.keys(row.raw_response) != 0) {
        surveyCategories[row.survey_id].categories.forEach((category) => {
          // for each category, get the score of the response
          let score = row.raw_response.result.values[category.ID];
          let column_title = row.survey_title + "-" + category.Name;
          if (!columnDict[column_title]) columnDict[column_title] = true; // add to list of possible columns.
          if (!zids[row.learner]) zids[row.learner] = {};
          zids[row.learner][column_title] = score;
        });
      }
    });
    console.log(data);
    const dataSource = this.unpack(zids);
    const columns = Object.keys(columnDict);

    this.setState({
      dataSource,
      columns,
      selectedColumns: columns,
      loading: false,
    });
  };

  createColumns = (columns) => {
    let tableColumns = columns.map((column) => {
      return {
        title: column,
        dataIndex: column,
        key: column,
        render: (text, record) => {
          return <span>{record[column]}</span>;
        },
      };
    });
    tableColumns.unshift({
      title: "zID",
      dataIndex: "zid",
      key: "zid",
      render: (text, record) => {
        return <span>{record.zid}</span>;
      },
    });
    return tableColumns;
  };

  unpack = (dictionary) => {
    let rows = [];
    let keys = Object.keys(dictionary).forEach((zid) => {
      let row = {};
      row["zid"] = zid;
      Object.keys(dictionary[zid]).forEach((col) => {
        // create the key value pairs here
        row[col] = dictionary[zid][col];
      });
      rows.push(row);
    });
    return rows;
  };

  joinTable = () => {
    const columns = this.createColumns(this.state.selectedColumns);
    return (
      <Table
        bordered
        dataSource={this.state.dataSource}
        rowKey={(row) => row.zid}
        columns={columns}
        pagination={true}
        loading={this.state.loading}
      />
    );
  };

  exportCSV = () => {
    const fields = this.state.selectedColumns;
    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(this.state.dataSource);
    var pom = document.createElement("a");
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var url = URL.createObjectURL(blob);
    pom.href = url;
    let filename = this.props.selectedCourse.id + ".csv";
    pom.setAttribute("download", filename);
    pom.click();
    console.log(csv);
  };

  checkboxChange = (e) => {
    this.setState({ selectedColumns: e });
  };

  render = () => {
    const { selectedCourse } = this.props;
    const options = this.createOptions(this.state.columns);

    return (
      <div>
        <div>
          <Button onClick={() => this.props.onCancel()}>Back</Button>
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={() => {
              this.exportCSV();
            }}
          >
            Export
          </Button>
        </div>
        <Divider />
        <div>
          <p style={{ fontSize: 24 }}>
            <b>{selectedCourse.code}</b> - {selectedCourse.title}
          </p>
          <p>[{selectedCourse.term}]</p>
        </div>
        <div>
          <Checkbox.Group
            options={options}
            onChange={this.checkboxChange}
            value={this.state.selectedColumns}
          />
        </div>
        <div style={{ padding: 15 }}>{this.joinTable()}</div>
        {/* <CourseExportModal 
          columns={this.state.columns}
          onCancel={() => this.setState({visibleExportModal: false})}
          visible={this.state.visibleExportModal}
          dataSource
        /> */}
      </div>
    );
  };
}

export default CourseExport;
