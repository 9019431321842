import React, { Component } from "react";
import Highlighter from "react-highlight-words";

import {
  Table,
  Button,
  Divider,
  Popconfirm,
  Input,
  Icon,
  notification,
  Tooltip,
  Tag,
} from "antd";

import axios from "axios";

import ApplicationForm from "./ApplicationForm";

class Application extends Component {
  state = {
    dataSource: [],
    onVisibleCreateView: false,
    fetching: true,
    searchText: "",
  };

  componentDidMount = async () => {
    const { data: dataSource } = await axios.get("survey/");

    console.log(dataSource);

    this.setState({ dataSource, fetching: false });
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };
  handleOnAddApplication = async (course, props) => {
    const { selectedRow } = this.state;
    const newSurveyApplication = { ...selectedRow, ...course };
    // console.log(newSurveyApplication);
    this.setState({ fetching: true });

    try {
      const { data } = selectedRow
        ? await axios.put("survey/", newSurveyApplication)
        : await axios.post("survey/", newSurveyApplication);
      const index = this.state.dataSource.findIndex(
        (record) => record.id === data.id
      );
      props.resetFields();
      if (selectedRow) {
        this.openNotificationWithIcon("success", "Record updated successfully");
      } else {
        this.openNotificationWithIcon("success", "Record created successfully");
      }

      const dataSource =
        index < 0
          ? [...this.state.dataSource, data]
          : [
              ...this.state.dataSource.slice(0, index),
              data,
              ...this.state.dataSource.slice(index + 1),
            ];
      this.setState({
        dataSource,
        onVisibleCreateView: false,
        fetching: false,
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  handleOnDeleteStudent = async (id) => {
    this.setState({ fetching: true });
    try {
      await axios.delete("survey/", { data: { id } });
      const dataSource = this.state.dataSource.filter(
        (record) => record.id !== id
      );

      this.setState({ dataSource, fetching: false });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  searchEntry(dataSource, searchText) {
    const text = searchText.toLowerCase();
    return dataSource.filter(
      (it) =>
        (!it.title && !it.description && !it.survey_id) ||
        (it.title && it.title.toLowerCase().includes(text)) ||
        (it.description && it.description.toLowerCase().includes(text)) ||
        (it.survey_id && it.survey_id.toLowerCase().includes(text))
    );
  }

  import = async () => {
    // const { data : dataSource } = await axios.post("/admin/import_responses/")
    // console.log(dataSource)
    const { data: dataSource } = await axios.post("/admin/add_survey_data/");
  };

  // deleteResponses = async () => {
  //   const { data : dataSource } = await axios.post("/admin/delete_responses/")
  // }

  // addResponses = async () => {
  //   const { data : dataSource } = await axios.post("/admin/add_responses/")
  //   console.log(dataSource)
  // }

  // fixErrors = async () => {
  //   const { data : dataSource } = await axios.post("/admin/fix_errors/")
  //   console.log(dataSource)
  // }

  TableView = () => {
    const { dataSource, fetching, searchText } = this.state;

    const columns = [
      {
        title: "Title",
        key: "title",
        dataIndex: "title",
        render: (text, record) => {
          return text ? (
            <>
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
              />

              <Tooltip placement="topLeft" title={record.description}>
                <Icon style={{ marginLeft: "10px" }} type="info-circle" />
              </Tooltip>
            </>
          ) : (
            {}
          );
        },
      },

      {
        title: "Survey",
        key: "survey",
        dataIndex: "survey_id",
        render: (text) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        },
      },

      {
        title: "Is Survey Data Fetched",
        key: "is_survey_data_fetched",
        dataIndex: "is_survey_data_fetched",
        render: (text, record) => {
          return text ? (
            <Tag color="green">Yes</Tag>
          ) : (
            <Tooltip
              placement="topLeft"
              title={JSON.stringify(record.fetch_error)}
            >
              <Tag color="red">No</Tag>
            </Tooltip>
          );
        },
      },

      {
        title: "Scales",
        key: "scales",
        dataIndex: "scales",
        render: (scales) => {
          return scales
            ? scales.map((scale) => (
                <Tag key={scale.ID} color="volcano">
                  {scale.Name}
                </Tag>
              ))
            : "";
        },
      },

      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <span>
            <Button
              type="link"
              disabled={record.saved}
              onClick={() =>
                this.setState({
                  selectedRow: record,
                  onVisibleCreateView: true,
                })
              }
            >
              Edit
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleOnDeleteStudent(record.id)}
            >
              <Button disabled={!record.is_deletable} type="link">
                Delete
              </Button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px" }}>
            Applications
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%",
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title, Description or Survey"
              onChange={(e) => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />
            <Button
              type="primary"
              onClick={() => {
                this.import();
              }}
            >
              Import
            </Button>
            <>
              <Button
                style={{ marginLeft: "10px", float: "right" }}
                type="primary"
                onClick={() => {
                  this.setState({
                    onVisibleCreateView: true,
                    selectedRow: undefined,
                  });
                }}
              >
                Create New
              </Button>
            </>
          </div>
          <Table
            rowKey={(record) => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
            pagination={{
              position: "both",
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
              defaultPageSize: this.getPageSize(),
            }}
            onChange={(e) => this.setPageSize(e.pageSize)}
          />
          <ApplicationForm
            onAddStudent={this.handleOnAddApplication}
            onVisibleCreateView={this.state.onVisibleCreateView}
            selectedApplication={this.state.selectedRow}
            onCancel={() => this.setState({ onVisibleCreateView: false })}
            loading={fetching}
          />
        </div>
      </>
    );
  };

  getPageSize = () => {
    const size = localStorage.getItem("tm-survey-pageSize");
    if (size) return parseInt(size);
    return 10;
  };

  setPageSize = (pageSize) => {
    localStorage.setItem("tm-survey-pageSize", pageSize);
  };

  render() {
    return this.TableView();
  }
}

export default Application;
