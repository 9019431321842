import React, { Component } from "react";

import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Popconfirm,
  Select,
  Table,
  Checkbox,
  InputNumber,
} from "antd";
import uuid from "uuid";

class CourseFrom extends Component {
  state = { htmlPreview: "", surveyList: [] };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.surveyList)
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const group = { ...values, surveys: this.state.surveyList };
        console.log(group)
        this.props.onAddcourse(group);
      }
    });
  };

  componentDidMount = () => {
    if (this.props.selectedGroup) {
      this.setState({ htmlPreview: this.props.selectedGroup.description });
      const { surveys: surveyList } = this.props.selectedGroup;
      console.log(surveyList)
      this.setState({ surveyList });
      console.log(this.props)
    }
  };

  onChangeRepetitions = (key, value) => {
    const item = {
      ...this.state.surveyList.find((x) => x.id === key),
      crpt: value,
    };

    const index = this.state.surveyList.findIndex((x) => x.id === key);
    const surveyList = [
      ...this.state.surveyList.filter((x) => x.id !== key).slice(0, index),
      item,
      ...this.state.surveyList.filter((x) => x.id !== key).slice(index),
    ];

    this.setState({ surveyList });
  }

  onSelectChange = (key, value, type) => {
    const item = {
      ...this.state.surveyList.find((x) => x.id === key),
      [type]: value,
    };

    const index = this.state.surveyList.findIndex((x) => x.id === key);
    const surveyList = [
      ...this.state.surveyList.filter((x) => x.id !== key).slice(0, index),
      item,
      ...this.state.surveyList.filter((x) => x.id !== key).slice(index),
    ];

    this.setState({ surveyList });
  };

  addNew = () => {
    const surveyList = [
      ...this.state.surveyList,
      {
        id: uuid.v4(),
        survey: null,
        enforced_repeat: false,
        enable_report: false,
        custom_parameters: "",
        preferred_title: "",
        repetitions: 0,
      },
    ];
    this.setState({ surveyList });
  };

  onDelete = (key) => {
    const surveyList = [...this.state.surveyList.filter((x) => x.id !== key)];
    this.setState({ surveyList });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedGroup, surveys } = this.props;
    const { surveyList } = this.state;

    const columns = [
      {
        title: "Survey",
        dataIndex: "survey",
        key: "surevy",
        render: (text, record) => (
          <Select
            optionFilterProp="name"
            onChange={(key) => this.onSelectChange(record.id, key, "survey")}
            value={text}
          >
            {surveys.map((survey) => (
              <Select.Option
                key={survey.id}
                value={survey.id}
                name={survey.title}
              >
                {survey.title}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        title: "Enforce Repeat",
        dataIndex: "enforced_repeat",
        key: "enforced_repeat",
        render: (text, record) => (
          <Checkbox
            onChange={(e) =>
              this.onSelectChange(
                record.id,
                e.target.checked,
                "enforced_repeat"
              )
            }
            checked={text}
          ></Checkbox>
        ),
      },

      {
        title: "Enable Report",
        dataIndex: "enable_report",
        key: "enable_report",
        render: (text, record) => (
          <Checkbox
            onChange={(e) =>
              this.onSelectChange(record.id, e.target.checked, "enable_report")
            }
            checked={text}
          ></Checkbox>
        ),
      },

      {
        title: "Repetitions",
        dataIndex: "repetitions",
        key: "repetitions",
        render: (text, record) => (
          <InputNumber min={0} max={30} defaultValue={record.repetitions} onChange={(e) => {this.onSelectChange(record.id, e, "repetitions")}}/>
        ),
      },

      {
        title: "Custom Parameters",
        dataIndex: "custom_parameters",
        key: "custom_parameters",
        render: (text, record) => (
          <Input defaultValue={record.custom_parameters} onChange={(e) => {this.onSelectChange(record.id, e.target.value, "custom_parameters")}}/>
        ),
      },

      {
        title: "Preferred Title",
        dataIndex: "preferred_title",
        key: "preferred_title",
        render: (text, record) => (
          <Input
            onChange={(e) =>
              this.onSelectChange(record.id, e.target.value, "preferred_title")
            }
            value={text}
          ></Input>
        ),
      },

      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <span>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.onDelete(record.id)}
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Title">
              {getFieldDecorator("title", {
                initialValue: selectedGroup ? selectedGroup.title : "",
                rules: [
                  { required: true, message: "Please enter course Title" },
                ],
              })(<Input placeholder="Please enter course Title" />)}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={18}>
            <Form.Item label="Description">
              {getFieldDecorator("description", {
                initialValue: selectedGroup ? selectedGroup.description : "",
                rules: [],
              })(<Input.TextArea placeholder="please enter  description" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <h3 style={{ marginBottom: "20px" }}>
            Sharing{" "}
            <span style={{ fontSize: "10px", color: "red" }}>
              (Cascading to all groups and surveys attached to this course)
            </span>
          </h3>

          <Col span={12}>
            <Form.Item>
              {getFieldDecorator("is_public", {
                valuePropName: "checked",
                initialValue: selectedGroup ? selectedGroup.is_public : false,
              })(
                <Checkbox style={{ lineHeight: "32px" }}> Is Public</Checkbox>
              )}
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item label="Members">
              {getFieldDecorator("members", {
                initialValue: selectedCourse ? selectedCourse.members : [],
              })(
                <Select mode="multiple" optionFilterProp="name">
                  {members.map((group) => (
                    <Select.Option
                      key={group.id}
                      value={group.id}
                      name={group.title}
                    >
                      {group.title}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col> */}
        </Row>
        <div style={{ width: "100%" }}>
          <div
            style={{
              marginTop: "20px",
              marginBottom: "7px",
              width: "100%",
            }}
          >
            <>
              <Button type="primary" onClick={this.addNew}>
                Add New
              </Button>
            </>
          </div>
          <Table
            rowKey={(row) => row.id}
            bordered
            columns={columns}
            dataSource={surveyList}
          ></Table>
        </div>

        <Form.Item wrapperCol={{ offset: 16 }}>
          <Popconfirm
            title="Sure to cancel?"
            onConfirm={() => this.props.onCancel()}
          >
            <Button style={{ marginRight: "10px" }} type="danger">
              Cancel
            </Button>
          </Popconfirm>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: "register" })(CourseFrom);
