import React, { Component } from "react";

import Highlighter from "react-highlight-words";

import {
  Table,
  Button,
  Divider,
  Popconfirm,
  Input,
  Icon,
  notification,
} from "antd";

import axios from "axios";

class Tranform extends Component {
  state = { fetching: true, searchText: "", dataSource: [] };

  componentDidMount = async () => {
    const { data: dataSource } = await axios.get("survey/transform/");

    this.setState({ dataSource, fetching: false });
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

  searchEntry(dataSource, searchText) {
    const text = searchText.toLowerCase();
    return dataSource.filter(
      (it) =>
        (!it.code && !it.survey.title) ||
        (it.code && it.code.toLowerCase().includes(text)) ||
        (it.survey.title && it.survey.title.toLowerCase().includes(text))
    );
  }

  onClickGenerate = async () => {
    this.setState({ fetching: true });
    const { data: dataSource } = await axios.get("survey/transform/generate/");

    this.setState({ dataSource, fetching: false });
  };

  getTransformerList = () => {
    const { dataSource, fetching, searchText } = this.state;

    const columns = [
      {
        title: "Code",
        key: "code",
        dataIndex: "code",
        sorter: (a, b) => {
          if (a.title < b.title) return 1;
          else if (a.title > b.title) return -1;
          return 0;
        },
        render: (text) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        },
      },
      {
        title: "Value",
        key: "value",
        dataIndex: "value",
      },
      {
        title: "Survey",
        key: "survey",
        dataIndex: "survey.title",
        sorter: (a, b) => {
          if (a.code < b.code) return 1;
          else if (a.code > b.code) return -1;
          return 0;
        },
        render: (text) => {
          return text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            {}
          );
        },
      },

      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <span>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleOnDeleteTemplate(record.id)}
            >
              <Button disabled={true} type="link">
                Delete
              </Button>
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ marginLeft: "10px", marginBottom: "20px" }}>
            Transformers
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%",
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title, Survey"
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />

            <>
              <Button
                style={{ marginLeft: "10px", float: "right" }}
                type="primary"
                onClick={this.onClickGenerate}
              >
                Refresh
              </Button>
            </>
          </div>
          <Table
            rowKey={(record) => record.id}
            bordered
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
            loading={fetching}
            pagination={{
              position: "both",
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
            }}
            onChange={(e) => this.setPageSize(e.pageSize)}
          />
        </div>
      </>
    );
  };
  render() {
    return <div>{this.getTransformerList()}</div>;
  }
}

export default Tranform;
