import React, { Component } from "react";
import debounce from "lodash/debounce";

import axios from "axios";

import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Popconfirm,
  Select,
  InputNumber,
  Checkbox,
} from "antd";

class CourseFrom extends Component {
  state = {
    htmlPreview: "",
    oldMoodleId: "",
    newMoodleId: "",
    members: [],
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //console.log({ ...values });
        this.props.onAddcourse({ ...values });
      }
    });
  };

  componentDidMount = () => {
    const { selectedCourse } = this.props;

    if (selectedCourse) {
      const members = selectedCourse.members;
      this.props.form.setFieldsValue({
        members: selectedCourse.members.map((program) => program.zid),
      });

      this.setState({
        htmlPreview: selectedCourse.description,
        oldMoodleId: selectedCourse.moodle_id,
        newMoodleId: selectedCourse.moodle_id,
        members,
      });
    }
  };

  onSearchMemeber = async (searchKey) => {
    const { data } = await axios.get(
      `admin/user-search/?search_key=${searchKey}`
    );
    this.setState({ members: data });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedCourse, groups, terms } = this.props;
    const { htmlPreview, oldMoodleId, newMoodleId, members } = this.state;

    return (
      <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Code">
              {getFieldDecorator("code", {
                initialValue: selectedCourse ? selectedCourse.code : "",
                rules: [
                  { required: true, message: "Please enter course Code" },
                ],
              })(
                <Input
                  disabled={!!selectedCourse}
                  placeholder="Please enter course Code"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Term">
              {getFieldDecorator("term", {
                initialValue: selectedCourse ? selectedCourse.term : "",

                rules: [{ required: true, message: "Please enter Term" }],
              })(
                <Select optionFilterProp="name" disabled={!!selectedCourse}>
                  {terms.map((term) => (
                    <Select.Option
                      key={term.id}
                      value={term.id}
                      name={term.label}
                    >
                      {"[" + term.id + "] - " + term.description}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Title">
              {getFieldDecorator("title", {
                initialValue: selectedCourse ? selectedCourse.title : "",
                rules: [
                  { required: true, message: "Please enter course Title" },
                ],
              })(<Input placeholder="Please enter course Title" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Moodle ID"
              validateStatus={
                selectedCourse && oldMoodleId !== newMoodleId ? "error" : ""
              }
              help={
                selectedCourse && oldMoodleId !== newMoodleId
                  ? "This change may cause some serious consequences"
                  : ""
              }
            >
              {getFieldDecorator("moodle_id", {
                initialValue: selectedCourse ? selectedCourse.moodle_id : null,

                rules: [],
              })(
                <InputNumber
                  onChange={(value) => this.setState({ newMoodleId: value })}
                  style={{ width: "100%" }}
                  placeholder="please enter course Id "
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Description">
              {getFieldDecorator("description", {
                initialValue: selectedCourse ? selectedCourse.description : "",
                rules: [],
              })(
                <Input.TextArea
                  rows={10}
                  onChange={(e) => {
                    this.setState({ htmlPreview: e.target.value });
                  }}
                  placeholder="please enter  description"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            Preview
            <div
              style={{
                height: "220px",
                border: "2px black solid",
                marginBottom: 20,
                marginTop: 9,
                padding: 10,
              }}
              dangerouslySetInnerHTML={{
                __html: htmlPreview,
              }}
            ></div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="Groups">
              {getFieldDecorator("groups", {
                initialValue: selectedCourse ? selectedCourse.groups : [],
              })(
                <Select mode="multiple" optionFilterProp="name">
                  {groups.map((group) => (
                    <Select.Option
                      key={group.id}
                      value={group.id}
                      name={`${group.id} - ${group.title}`}
                    >
                      [{group.id}] - {group.title}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <h3 style={{ marginBottom: "20px" }}>
            Sharing{" "}
            <span style={{ fontSize: "10px", color: "red" }}>
              (Cascading to all groups and surveys attached to this course)
            </span>
          </h3>

          <Col span={12}>
            <Form.Item>
              {getFieldDecorator("is_public", {
                valuePropName: "checked",
                initialValue: selectedCourse ? selectedCourse.is_public : false,
              })(
                <Checkbox style={{ lineHeight: "32px" }}> Is Public</Checkbox>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Members">
              {getFieldDecorator(
                "members",
                {}
              )(
                <Select
                  allowClear
                  showSearch
                  onSearch={debounce(this.onSearchMemeber, 250)}
                  mode="multiple"
                  optionFilterProp="children"
                >
                  {members.map((member) => (
                    <Select.Option
                      key={member.zid}
                      value={member.zid}
                      name={member.zid}
                    >
                      {`${member.zid} - ${member.display_name}(${member.email})`}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item wrapperCol={{ offset: 16 }}>
          <Popconfirm
            title="Sure to cancel?"
            onConfirm={() => this.props.onCancel()}
          >
            <Button style={{ marginRight: "10px" }} type="danger">
              Cancel
            </Button>
          </Popconfirm>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: "register" })(CourseFrom);
