import React from "react";
import img2 from "./resources/bg-pattern.png";
function ItemListView({ anchor, title, items }) {
  return (
    <div
      className="traits"
      id={anchor}
      style={{
        background: `url(${img2}),linear-gradient(to right, rgba(29,28,28,0.81), rgba(89,86,86,0.82))`,
      }}
    >
      <div className="container">
        {" "}
        <h1
          style={{
            fontSize: "50px",
            textAlign: "left",
            color: "white",
          }}
        >
          {title}
        </h1>
        {items.map((trait) => (
          <div key={trait.id}>
            <h3
              style={{
                fontSize: "30px",
                color: "white",
                borderBottom: "solid thin white",
              }}
            >
              <i className="fa fa-flag" aria-hidden="true"></i> {trait.title}
            </h3>
            <article style={{ color: "white", fontSize: "20px" }}>
              {trait.description}
            </article>
            <br />
            <br />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ItemListView;
