import React from "react";
import img2 from "./resources/bg-pattern.png";
import { Row } from "antd";

function TextView({ title, description, anchor }) {
  return (
    <div
      id={anchor}
      className="intro"
      style={{
        background: `url(${img2}),linear-gradient(to left, #005964, #005d3c)`,
      }}
    >
      <div className="container">
        <Row className="intro-cont">
          <h1
            style={{
              fontSize: "100px",
              textAlign: "center",
              color: "white",
            }}
          >
            {title}
          </h1>
          <p
            style={{
              fontSize: "20px",
              textAlign: "center",
              color: "white",
            }}
          >
            {description}
          </p>
        </Row>
      </div>
    </div>
  );
}

export default TextView;
