import React, { Component } from "react";
import axios from "axios";

import {
  Button,
  Modal,
  Checkbox
} from "antd";


class CourseExportModal extends Component {
  state = {
  };

  createOptions = (columns) => {
    console.log(columns)
    let options = columns.map(col => {
        return (
            {
                label: col, value: col
            }
        )
    })
    return options
  }

  render = () => {

    const { selectedCourse } = this.props

    let options

    if (this.props.columns) {
      options = this.createOptions(this.props.columns)
    }

    return (
      <Modal
        visible={this.props.visible}
        title="Basic Modal"
        onCancel={this.props.onCancel}
      >
        <div>
          <p>Select the columns you want in the CSV</p>
          <Checkbox.Group options={options} />
        </div>
      </Modal>
    )
  }

}

export default CourseExportModal