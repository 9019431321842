import React, { Component } from "react";
import { Spin, Table, Divider, Row, Col, Icon } from "antd";
import axios from "axios";

class CourseView extends Component {
  state = { fetching: true };

  componentDidMount = async () => {
    const { id: courseID } = this.props.match.params;

    const { data: course } = await axios.get(`/course/${courseID}`);
    this.setState({ course, fetching: false });
  };
  render() {
    const { course, fetching } = this.state;

    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Link",
        dataIndex: "url",
        key: "link",
        render: (text, record) => (
          <Row>
            <Col span={12}>
              <span>
                <a
                  href={record.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  icon="link"
                >
                  <Icon type="link" />
                </a>
              </span>
            </Col>
          </Row>
        ),
      },
    ];
    // const { groups } = course;
    return (
      <div style={{ backgroundColor: "white" }}>
        <div style={{ padding: "10px" }}>
          <div style={{ display: "flex" }}>
            <img
              style={{ height: "100px" }}
              src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_sydney.png"
              alt="UNSW Logo"
            />

            <div
              style={{
                marginLeft: "20px",
                marginTop: "25px",
                fontSize: "32px",
              }}
            >
              TM
            </div>
          </div>
          <Divider style={{ borderTop: "10px solid #ffe600" }} />
          <div>
            {fetching ? (
              <div
                style={{
                  textAlign: "center",
                  background: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  marginBottom: "20px",
                  padding: "30px 50px",
                  margin: " 20px 0",
                }}
              >
                <Spin />
              </div>
            ) : (
              <div>
                <div style={{ marginBottom: "20px" }}>
                  <span
                    style={{
                      fontSize: "40px",
                      fontWeight: "bold",
                    }}
                  >
                    {`${course.code} - ${course.title}`}
                  </span>
                </div>
                {course.groups.map((group) => (
                  <div
                    style={{
                      background: "#dfdfdf",
                      padding: "20px",
                      marginBottom: "30px",
                    }}
                    key={group.id}
                  >
                    <div style={{ backgroundColor: "#d6c5ce", padding: "5px" }}>
                      <Table
                        bordered
                        title={() => (
                          <div
                            style={{
                              color: "green",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            {group.title}
                          </div>
                        )}
                        dataSource={group.surveys}
                        rowKey={(row) => row.id}
                        columns={columns}
                        pagination={false}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default CourseView;
